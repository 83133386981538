import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {about} from '../../data';
import './About.css';
import resume from './resume.pdf';
import UpWorkSvg from './upwork';
import {useCustomTheme} from '../../contexts/theme';
import {Box, Button, Typography, useTheme} from '@mui/material';
import React from 'react';
import Background from '../Header/Background';
import Header from '../Header/Header';
import {useGeneralEndPoint} from '../../GeneralEndPoint';
///fixing auto deployment
const About = () => {
  const {name, role, exp, social} = about;
  const {projectsTotal, isMobile} = useGeneralEndPoint();
  const {isDark, pallette} = useCustomTheme();
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        // mt: '6%',
        height: '80vh',
        position: 'relative',
        top: 0,
      }}>
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          // height: '80vh',
        }}>
        <Header />
        <Box
          sx={{
            width: '80%',
            mx: '05%',
          }}>
          <Box
            sx={{
              // ml: '10vw',
              width: 'fit-content',
              px: '.7vw',
              background: pallette.background,
            }}>
            <Typography
              sx={{
                fontSize: isMobile ? '2rem' : '6vh',
                fontWeight: 'bold',
                color: pallette.textPrimary,
              }}>
              Hi, I am{' '}
              <span
                style={{
                  color: pallette.textSecondary,
                }}>
                {name}.
              </span>
            </Typography>
          </Box>
          <Box
            sx={{
              alignSelf: 'left',
              width: 'fit-content',
              px: '.7vw',
              background: pallette.background,
            }}>
            <Typography
              sx={{
                fontSize: isMobile ? '2rem' : '4vh',
                color: pallette.textPrimary,
                fontWeight: 'bold',
              }}>
              A {role}.
            </Typography>
          </Box>
          <Box
            sx={{
              alignSelf: 'left',
              width: 'fit-content',
              px: '.7vw',
              // background: themeName === 'dark' ? theme.palette.text.dark : theme.palette.primary.light,
              background: pallette.background,
            }}>
            <Typography
              sx={{
                fontSize: isMobile ? '1rem' : '3vh',
                color: pallette.textPrimary,
                fontWeight: 'bold',
              }}>
              <span
                style={{
                  color: pallette.textSecondary,
                  fontSize: isMobile ? '2rem' : '4vh',
                }}>
                +{exp}
              </span>{' '}
              years of experience.
            </Typography>
          </Box>
          <Box
            sx={{
              alignSelf: 'left',
              width: 'fit-content',
              px: '.7vw',
              background: pallette.background,
            }}>
            <Typography
              sx={{
                fontSize: isMobile ? '1rem' : '3vh',
                color: pallette.textPrimary,
                fontWeight: 'bold',
                pb: '1vh',
              }}>
              <span
                style={{
                  color: pallette.textSecondary,
                  fontSize: isMobile ? '2rem' : '4vh',
                }}>
                +{projectsTotal}
              </span>{' '}
              projects.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            mx: '5%',
            // mt: '5%',
            justifySelf: 'bottom',
          }}></Box>
        {/* <Box
          sx={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            gap: '2rem',
          }}
        >
          {social.github && (
            <a href={social.github} target="_blank" aria-label="github" className="link link--icon">
              <GitHubIcon />
            </a>
          )}
          {social.linkedin && (
            <a href={social.linkedin} target="_blank" aria-label="linkedin" className="link link--icon">
              <LinkedInIcon />
            </a>
          )}
          {social.linkedin && (
            <a href={social.upwork} target="_blank" aria-label="upwork" className="link link--icon">
              <UpWorkSvg
                bg={themeName === 'dark' ? '#23283e' : '#fcfcfc'}
                fill={themeName === 'dark' ? '#bdbddd' : '#555'}
              />
            </a>
          )}
        </Box> */}
      </Box>
      <Background />
    </Box>
  );
};

export default About;
