const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://moatezz.com',
  title: 'JS.',
};

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Moatezz Chabane',
  role: 'Full Stack Web & Mobile apps Developer',
  exp: '5',
  resume: '/resume.pdf',
  social: {
    linkedin: 'https://www.linkedin.com/in/moatezz',
    github: 'https://github.com/moatezz',
    upwork: 'https://www.upwork.com/freelancers/~01bef6731ef71a426d',
  },
};

const projects = [
  {
    name: 'Ivy Net',
    description: 'Currently Working on this',
    stack: ['Tailwind CSS', 'Next JS'],
    img: '/assets/p38.webp',
    livePreview: 'https://ivynet.dev/',
  },
  {
    name: 'Night Captital',
    description: 'Currently Working on this',
    stack: ['Webflow'],
    img: '/assets/p37.webp',
    livePreview: 'https://www.nightcapvc.com/',
  },
  {
    name: 'Ocean Properties',
    description: 'Currently Working on this',
    stack: ['Strapi', 'Tailwind CSS', 'Apollo', 'PostgresSQL', 'Next JS'],
    img: '/assets/p36.webp',
    livePreview: 'https://oceanproperties.pt/',
  },
  {
    name: 'Obake.blue',
    description: 'Currently Working on this',
    stack: ['Nest JS', 'Apollo', 'MongoDB', 'Next JS'],
    img: '/assets/p31.webp',
    livePreview: 'https://obake.blue/',
  },
  {
    name: 'Cocoon Records',
    description: 'Currently Working on this',
    stack: ['Next JS', 'MUI', 'PostgreSQL'],
    img: '/assets/p32.webp',
    livePreview: 'https://cocoon-records.info/',
  },
  {
    name: 'ILY GIRL',
    description: 'Currently Working on this',
    stack: ['Next JS', 'Tailwind', 'SQLite', 'React-query', 'Framer'],
    img: '/assets/p33.webp',
    livePreview: 'https://ily-girl.mikapikazo.info/',
  },
  {
    name: '100 Days of poetry',
    description: 'Currently Working on this',
    stack: ['Tailwind', 'React-query', 'Framer'],
    img: '/assets/p34.webp',
    livePreview: 'https://100daysofpoetry.gallery/',
  },
  {
    name: 'Ventola',
    description: 'Currently Working on this',
    stack: ['Nest JS', 'Java', 'Atlas', 'Realm', 'Apollo', 'MongoDB', 'React Native'],
    img: '/assets/p6.webp',
    livePreview: 'https://winstonred.com',
  },
  {
    name: 'Michal Maciejewski',
    description: 'Smooth portfolio with emphasis on UX',
    stack: ['React', 'Redux', 'Material-UI', 'Typescript'],
    img: '/assets/p9.webp',
    livePreview: 'https://portfolio-new-mw.webflow.io/',
  },
  {
    name: 'Hanouty',
    description:
      'Hanouty is the perfect app for store owners and managers looking to streamline their business operations and stay on top of their stock and sales. ',
    stack: ['Nest JS', 'Java', 'Atlas', 'Realm', 'Apollo', 'MongoDB', 'React Native'],
    img: '/assets/p7.webp',
    livePreview: 'https://play.google.com/store/apps/details?id=com.winstonred.hanouty',
  },
  {
    name: 'Emi Lantz',
    description: 'Smooth portfolio with emphasis on UX',
    stack: ['React', 'Redux', 'Material-UI', 'Typescript'],
    img: '/assets/p8.webp',
    livePreview: 'https://www.emidesigned.com/',
  },
  {
    name: 'Pbike',
    description:
      'Pbike is website that allow you to run a bar bike, one of the simplest project I worked but I liked coding the design. that was fun. ',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p5.webp',
    livePreview: 'https://pbike.pt',
  },
  {
    name: 'VIVIVIT',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p12.webp',
    livePreview: 'https://www.vivivit.com/',
  },
  {
    name: 'Defi-UI',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p1.webp',
    livePreview: 'https://defiui.io/',
  },

  {
    name: 'Jung Hoe',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p10.webp',
    livePreview: 'https://www.junghoe.com/',
  },
  {
    name: 'Hamsy',
    description:
      'HMS Organization Platform for handling members, communication, documents and much more. Built with efficient reusability in mind. ',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    livePreview: 'http://hamsy.org',
    img: '/assets/p3.webp',
  },

  {
    name: 'Heck house',
    description:
      'Pbike is website that allow you to run a bar bike, one of the simplest project I worked but I liked coding the design. that was fun. ',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p11.webp',
    livePreview: 'https://heckhouse.com/',
  },
  {
    name: 'Eenar',
    description:
      'Eenar is an upcoming Linkedin-like platform that targets only a small niche of researchers, I loved working on this project, It was massive work but the result was awesome.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],

    livePreview: 'https://eenar-stage.herokuapp.com/ar/login',
    img: '/assets/p2.webp',
  },
  {
    name: 'Rwad',
    description:
      'Rwad is a training centers platform where they can have a digital place to organize and address their students and staff. this was my first big project and I loved it',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    livePreview: 'https://rwadcenter.com/',
    img: '/assets/p4.webp',
  },

  {
    name: 'Brik Portfolio',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p13.webp',
    livePreview: 'https://brik.co.jp/portfolio/',
  },
  {
    name: 'Gorod +',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p30.webp',
    livePreview: 'https://gorod-plus.tv/',
  },
  {
    name: 'Go Workship',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p14.webp',
    livePreview: 'https://goworkship.com/magazine/',
  },
  {
    name: 'Nversia',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p29.webp',
    livePreview: 'https://nversia.ru/',
  },
  {
    name: 'Shinrinkoen',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p15.webp',
    livePreview: 'https://www.shinrinkoen.jp/',
  },
  {
    name: 'Numu Azukano',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p16.webp',
    livePreview: 'https://numazukanko.jp/',
  },
  {
    name: 'Praktikum',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p28.webp',
    livePreview: 'https://praktikum.expertsouth.ru/',
  },
  {
    name: 'Laporte gosen',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p17.webp',
    livePreview: 'https://laporte-gosen.jp/',
  },
  {
    name: 'Expert south',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p27.webp',
    livePreview: 'https://expertsouth.ru/',
  },
  {
    name: 'Kariya Oasis',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p18.webp',
    livePreview: 'https://kariya-oasis.com/',
  },
  {
    name: '76 ru',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p25.webp',
    livePreview: 'https://76.ru/',
  },
  {
    name: 'Ibusuki',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p19.webp',
    livePreview: 'https://www.ibusuki.or.jp/',
  },
  {
    name: 'GPVN',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p24.webp',
    livePreview: 'https://gpvn.ru/',
  },
  {
    name: 'Yurihonjo Kanko',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p20.webp',
    livePreview: 'https://yurihonjo-kanko.jp/',
  },
  {
    name: 'Tsargrad',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p26.webp',
    livePreview: 'https://ekb.tsargrad.tv/',
  },
  {
    name: 'Ibusuki Nature',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p21.webp',
    livePreview: 'https://naturewellbeing-ibusuki.studio.site/',
  },
  {
    name: 'Studio AI',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p22.webp',
    livePreview: 'https://studio.design/',
  },
  {
    name: 'Tochinavi',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Express', 'Strapi', 'GraphQL', 'Apollo', 'Material-UI', 'React'],
    img: '/assets/p23.webp',
    livePreview: 'https://www.tochinavi.net/',
  },
  {
    name: 'Location N1',
    description:
      'Defi-UI is an agency Portfolio Website, where they present their digital products It has a dark crypto theme to it, What I loved the most about developing this project is coding the page transitions, just seems fun.',
    stack: ['Node', 'Hygraph', 'GraphQL', 'Apollo', 'Tailwind', 'React'],
    img: '/assets/p35.webp',
    livePreview: 'https://locationnumberone.com/',
  },
];

const skills = [
  {
    name: 'HTML',
    fontSize: '2',
    fontFamily: '',
    fontWeight: '800',
    color: '#006FFF',
    y: '6',
  },
  {
    name: 'CSS',
    fontSize: '4',
    fontFamily: '700',
    fontWeight: '',
    color: '#006FFF',
    y: '4',
  },
  {
    name: 'JavaScript',
    fontSize: '5',
    fontFamily: '',
    fontWeight: '200',
    color: '#0374FC',
    y: 5,
  },
  {
    name: 'TypeScript',
    fontSize: '8',
    fontFamily: '',
    fontWeight: '100',
    color: '#0374FC',
    y: '3',
  },
  {
    name: 'Firebase',
    fontSize: '2.2',
    fontFamily: '',
    fontWeight: '800',
    color: '#0779F8',
    y: '3',
  },
  {
    name: 'Redux',
    fontSize: '4.2',
    fontFamily: '',
    fontWeight: '',
    color: '#0779F8',
    y: '2',
  },
  {
    name: 'SASS',
    fontSize: '3.4',
    fontFamily: 'bold',
    fontWeight: '',
    color: '#0A7DF5',
    y: '3',
  },
  {
    name: 'MUI',
    fontSize: '4.2',
    fontFamily: '',
    fontWeight: '800',
    color: '#0A7DF5',
    y: '2',
  },
  {
    name: 'Git',
    fontSize: '2.4',
    fontFamily: '',
    fontWeight: '600',
    color: '#1187EE',
    y: '4',
  },
  {
    name: 'CI/CD',
    fontSize: '2',
    fontFamily: '',
    fontWeight: '',
    color: '#158CEA',
    y: '3',
  },
  {
    name: 'Jest',
    fontSize: '3.6',
    fontFamily: '',
    fontWeight: '700',
    color: '#158CEA',
    y: '2',
  },
  {
    name: 'GraphQL',
    fontSize: '2.4',
    fontFamily: '',
    fontWeight: '',
    color: '#1891E7',
    y: '3',
  },
  {
    name: 'Node',
    fontSize: '6',
    fontFamily: '',
    fontWeight: '100',
    color: '#1B95E4',
    y: 5,
  },
  {
    name: 'Express',
    fontSize: '4.2',
    fontFamily: '',
    fontWeight: '600',
    color: '#1F9AE0',
    y: '4',
  },
  {
    name: 'MongoDB',
    fontSize: '3.6',
    fontFamily: '',
    fontWeight: '500',
    color: '#229FDD',
    y: '3',
  },
  {
    name: 'Mongoose',
    fontSize: '2.4',
    fontFamily: '',
    fontWeight: '200',
    color: '#26A4D9',
    y: '2',
  },
  {
    name: 'Apollo',
    fontSize: '4.2',
    fontFamily: '',
    fontWeight: '800',
    color: '#29A9D6',
    y: '4',
  },
  {
    name: 'Strapi',
    fontSize: '3.4',
    fontFamily: '',
    fontWeight: '400',
    color: '#2CADD3',
    y: '2',
  },
  {
    name: 'React',
    fontSize: '8',
    fontFamily: '',
    fontWeight: '800',
    color: '#30B2CF',
    y: '-1',
  },
  {
    name: 'Redis',
    fontSize: '6',
    fontFamily: '',
    fontWeight: '700',
    color: '#33B7CC',
    y: '10',
  },
  {
    name: 'Meteor',
    fontSize: '4',
    fontFamily: '',
    fontWeight: '200',
    color: '#26A4D9',
    y: '3',
  },
  {
    name: 'Next',
    fontSize: '10',
    fontFamily: '',
    fontWeight: '900',
    color: '#3AC0C5',
    y: 5,
  },
  {
    name: 'Fastify',
    fontSize: '2',
    fontFamily: '',
    fontWeight: '200',
    color: '#3EC5C1',
    y: '-',
  },
  {
    name: 'Nest',
    fontSize: '10',
    fontFamily: '',
    fontWeight: '100',
    color: '#41CABE',
    y: '-2',
  },
  {
    name: 'Astro',
    fontSize: '4',
    fontFamily: '',
    fontWeight: '300',
    color: '#44CFBB',
    y: 5,
  },
  {
    name: 'React Native',
    fontSize: '8',
    fontFamily: '',
    fontWeight: '300',
    color: '#44CFBB',
    y: '0',
  },
  {
    name: 'Ant Design',
    fontSize: '4',
    fontFamily: '',
    fontWeight: '200',
    color: '#48D4B7',
    y: '-2',
  },
  {
    name: 'Chakra',
    fontSize: '4',
    fontFamily: '',
    fontWeight: '600',
    color: '#4BD8B4',
    y: '-4',
  },
  {
    name: 'Tailwind',
    fontSize: '6',
    fontFamily: '',
    fontWeight: '500',
    color: '#4BD8B4',
    y: '-5',
  },
  {
    name: 'Supabase',
    fontSize: '2.4',
    fontFamily: '',
    fontWeight: '1000',
    color: '#4FDDB0',
    y: '3',
  },
];

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'hi@moatezz.com',
};

export {header, about, projects, skills, contact};
