import React, {useState, useEffect, useRef} from 'react';
import {Box} from '@mui/material';
import {projects} from '../../data';
import {useGeneralEndPoint} from '../../GeneralEndPoint';
// import {imageList} from '../../utils/imageList';

const Background = () => {
  const {isMobile} = useGeneralEndPoint();
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageList = projects.map((p) => p.img);
  const totalImages = imageList.length;
  const loopDuration = 3000; // Total duration of the loop in milliseconds

  const containerRef = useRef(null);
  const [cursorX, setCursorX] = useState(0);
  const [isMoving, setIsMoving] = useState(false); // Track cursor movement

  const handleMouseMove = (event) => {
    const containerRect = containerRef.current.getBoundingClientRect();
    const mouseX = event.clientX - containerRect.left;
    setCursorX(mouseX);
  };

  const handleMouseLeave = () => {
    setIsMoving(false);
    setCursorX(0); // Reset cursor position when leaving
  };

  useEffect(() => {
    let loopCount = 0;
    let timePerImage = loopDuration / totalImages;
    let interval = null;

    const accelerateInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);

      loopCount++;
      if (loopCount === totalImages) {
        clearInterval(accelerateInterval); // Stop the initial loop
        timePerImage = 500; // Set time per image to 1 second
        interval = setInterval(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
        }, timePerImage);
      }
    }, timePerImage);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const containerRect = containerRef.current.getBoundingClientRect();
    const maxSpeedFactor = 200; // Maximum speed increase factor
    const speedFactor = (cursorX / containerRect.width) * maxSpeedFactor;
    const newTimePerImage = Math.max(50, 500 - speedFactor * 45); // Adjust this range as needed

    let interval = null;

    if (isMoving) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
      }, newTimePerImage);
    } else {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
      }, 500); // Return to 1 second per image when cursor stops
    }

    return () => {
      clearInterval(interval);
    };
  }, [cursorX, isMoving]);

  return (
    <Box
      ref={containerRef}
      display={'flex'}
      justifyContent="center"
      alignItems="center"
      height={'80vh'}
      width={'100%'}
      onMouseMove={(event) => {
        if (!isMoving) {
          setIsMoving(true);
          handleMouseMove(event);
        }
      }}
      onMouseLeave={handleMouseLeave}
      sx={{
        position: 'absolute',
        top: 0,
        overflow: 'hidden',
        clipPath: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)', // Create trapezoid shape with shorter bottom
      }}>
      {imageList.map((imageUrl, index) => (
        <img
          key={index}
          src={imageUrl}
          alt={`Image ${index}`}
          style={{
            position: 'absolute',
            width: isMobile ? 'auto' : '100vw',
            height: '100vh',
            zIndex: 1,
            // transform: `translateX(-50)`, // Slide images horizontally
            // adjust the position of the image to be centered horiziantally when in mobile
            left: isMobile && `calc(-60%)`, // Adjust left position based on cursor position
            objectFit: 'cover', // Maintain aspect ratio and cover the container
            // filter: index === currentIndex ? 'brightness(0.8)' : 'brightness(0.5)', // Adjust brightness based on index
            transition: 'opacity 0.5s ease-in-out',
            opacity: index === currentIndex ? 1 : 0, // Show only the current image
          }}
        />
      ))}
    </Box>
  );
};

export default Background;
